import React from "react";
import {
  darken,
  desaturate,
  lighten,
  readableColor
  // transparentize
} from "polished";

import { RedocStandalone, RedocRawOptions } from "redoc";

function App() {
  const ifmFontFamilyBase = `system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`;

  const options: RedocRawOptions = {
    hideDownloadButton: true,
    theme: {
      colors: {
        primary: {
          main: "#32329f",
          light: ({ colors }) =>
            lighten(colors.tonalOffset, colors.primary.main),
          dark: ({ colors }) => darken(colors.tonalOffset, colors.primary.main),
          contrastText: ({ colors }) => readableColor(colors.primary.main)
        },
        success: {
          main: "#228665",
          light: ({ colors }) =>
            lighten(colors.tonalOffset, colors.success.main),
          dark: ({ colors }) => darken(colors.tonalOffset, colors.success.main),
          contrastText: ({ colors }) => readableColor(colors.success.main)
        },
        error: {
          main: "#c33232",
          light: ({ colors }) => lighten(colors.tonalOffset, colors.error.main),
          dark: ({ colors }) => darken(colors.tonalOffset, colors.error.main),
          contrastText: ({ colors }) => readableColor(colors.error.main)
        },
        warning: {
          main: "#d4ad03",
          light: ({ colors }) =>
            lighten(colors.tonalOffset, colors.warning.main),
          dark: ({ colors }) => darken(colors.tonalOffset, colors.warning.main),
          contrastText: "#ffffff"
        },
        responses: {
          error: {
            color: ({ colors }) => colors.error.main,
            backgroundColor: ({ colors }) => "#f2f2f2"
          }
        },
        text: {
          primary: "#061125",
          secondary: "#4e566d"
        },
        http: {
          get: "#228665",
          post: "#2363ae",
          put: "#9653d4",
          options: "#d3ca12",
          patch: "#f0841f",
          delete: "#c33232",
          basic: "#999",
          link: "#6f58c4",
          head: "#c167e4"
        }
      },
      spacing: {
        unit: 5,
        sectionHorizontal: ({ spacing }) => spacing.unit * 8,
        sectionVertical: ({ spacing }) => spacing.unit * 3
      },
      rightPanel: {
        backgroundColor: "#454d5c",
        width: "40%",
        textColor: "#ffffff"
      },
      schema: {
        nestedBackground: "#fafafa",
        linesColor: theme =>
          lighten(
            theme.colors.tonalOffset,
            desaturate(theme.colors.tonalOffset, theme.colors.primary.main)
          ),
        defaultDetailsWidth: "75%",
        typeNameColor: theme => theme.colors.text.secondary,
        typeTitleColor: theme => theme.schema.typeNameColor,
        requireLabelColor: theme => theme.colors.error.main,
        labelsTextSize: "15px",
        nestingSpacing: "10px",
        arrow: {
          size: "1.1em",
          color: theme => theme.colors.text.secondary
        }
      },
      typography: {
        fontSize: "15px",
        lineHeight: "19px",
        fontWeightRegular: "400",
        fontWeightBold: "600",
        fontWeightLight: "300",
        //--ifm-font-family-base
        fontFamily: ifmFontFamilyBase,
        // fontFamily: "openSans, Noto Sans, sans-serif",
        headings: {
          fontFamily: ifmFontFamilyBase,
          fontWeight: "400px"
        }
      },
      sidebar: {
        width: "300px"
      }
    }
  };
  return <RedocStandalone specUrl={process.env.REACT_APP_SPEC_PATH || '/spec.json'} options={options} />;
}

export default App;